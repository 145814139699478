










import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class PersonalTraining extends Vue {
}
